.checkInboxModal {
  :global {
    .modalContent {
      width:     90%;
      max-width: 360px;
    }
    .closeBtn {
      position: relative;
      top:      12px;
    }
    .header {
      padding:       0;
      margin-bottom: 20px;
      align-items:   flex-start;
      p {
        text-align: left;
      }
    }
    .content {
      p {
        text-align:    left;
        font-size: 18px;
        margin-bottom: 10px !important;
      }
    }
  }
}