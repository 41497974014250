.footer {
  background: rgba(0, 0, 0, 0.8);
  padding:    10px 0;
  width:      100%;
  position:   relative;
  z-index:    2;
  .content {
    display:         flex;
    align-items:     center;
    justify-content: space-between;
    flex-wrap:       wrap;
    gap:             20px;
  }
  .copyright {
    font-size:     9px;
    font-weight:   300;
    color:         white;
    margin-bottom: 0;
  }
  .links {
    display:         flex;
    align-items:     center;
    gap:             38px;
    list-style-type: none;
    a {
      text-decoration: none;
      color:           white;
      font-size:       14px;
      transition:      .3s linear;
      &:hover, &:active {
        opacity: .7;
      }
    }
  }
  .social {
    display:         flex;
    align-items:     center;
    gap:             18px;
    list-style-type: none;
    li {
      width: 22px;
      &:nth-child(4) {
        width: 20px;
      }
      a {
        text-decoration: none;
        transition:      .3s linear;
        &:hover, &:active {
          opacity: .7;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .footer {
    margin-top: 18px;
    padding:    24px 0;
    .content {
      flex-wrap:       wrap;
      justify-content: center;
      gap:             6px;
    }
    .links {
      order:        1;
      gap:          18px;
      margin-right: 20px;
    }
    .social {
      order: 2;
      li {
        width: 12px;
        &:nth-child(4) {
          width: 12px;
        }
      }
    }
    .copyright {
      order:      3;
      width:      100%;
      text-align: center;
    }
  }
}