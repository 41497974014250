.player_container {
  width: fit-content;
  height: fit-content;
  background-color: #ffd600;
  position: relative;

  border: 10px solid #ffd600;
  border-bottom: 30px solid #ffd600;

  @media (min-width: 768px) {
    border-bottom: 36px solid #ffd600;
  }
}

.img {
  height: 258px;
  width: 258px;

  @media (min-width: 768px) {
    height: 323px;
    width: 323px;
  }
}

.player {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.play {
  cursor: pointer;
  width: 70px;
  height: 70px
}