@import "../../../styles/variables";

.benefits {
  ul {
    margin-top:      24px;
    padding:         0;
    list-style-type: none;
    margin-bottom:   24px;
    li {
      color:          white;
      margin-bottom:  20px;
      display:        flex;
      align-items:    flex-start;
      font-weight:    800;
      letter-spacing: -0.9px;
      img {
        width:        32px;
        min-width:    32px;
        margin-right: 16px;
        max-height:   28px;
      }
    }
  }
}