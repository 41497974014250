@import "../../../styles/variables";

.spinner {
  position:        fixed;
  z-index:         1000;
  left:            0;
  top:             0;
  width:           100%;
  height:          100%;
  background:      rgba(black, 40%);
  display:         flex;
  align-items:     center;
  justify-content: center;
  .indicator {
    position:         relative;
    width:            3em;
    height:           3em;
    border:           5px solid rgba($yellow, 100%);
    border-top-color: transparent;
    border-radius:    50%;
    animation:        zoomIn 1s infinite linear;
  }
}

@keyframes zoomIn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}