@import "../../../styles/variables";

.countdown {
  font-size: 50px;
  font-weight: 200;
  letter-spacing: -0.06em;

  margin-bottom: 0;

  span {
    color: $yellow;
  }

  @media (min-width: 768px) {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .countdown {
    font-size: 40px;
  }
}
