.Toastify__toast-body {
  margin: unset !important;
  align-items: unset !important;
  padding-left: 20px;
}

.Toastify__close-button--light {
  opacity: 1;
}

.Toastify__close-button > svg {
  height: 26px;
  width: 24px;
}

.Toastify__close-button {
  margin-top: 20px;
  left: -20px;
  position: relative;
}

.Toastify__toast-container {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.toaster-notify {
  width: 100%;
  color: white;
  border-radius: 0;
  min-height: 245px;
  background-color: #000000;
  border: 10px solid #ffd600;

  svg {
    opacity: 1 !important;
    fill: #ffffff;
  }

  @media (min-width: 992px) {
    width: 434px;
  }
}
