@font-face {
  font-family: 'HK Grotesk';
  font-weight: 300;
  src:         url(../fonts/HKGrotesk-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'HK Grotesk';
  font-weight: 400;
  src:         url(../fonts/HKGrotesk-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'HK Grotesk';
  font-weight: 500;
  src:         url(../fonts/HKGrotesk-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'HK Grotesk';
  font-weight: 700;
  src:         url(../fonts/HKGrotesk-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'HK Grotesk';
  font-weight: 900;
  src:         url(../fonts/HKGrotesk-Black.ttf) format('truetype');
}