@import "../../../styles/variables";

.layout {
  min-height: 100vh;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .logo {
    display: block;
    text-align: center;
    padding: 52px 0 70px 0;
    img {
      width: 48px;
    }
  }
  .background_container {
    z-index: 0;
  }
  .background_video {

    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
  }
  .shadow_container {
    position: fixed;
    width: 100vw;
    height: 100vh;

    @media (min-width: 768px) {
      background: linear-gradient(
        90deg,
        rgb(0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 40%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
  .background {
    height: 100vh;
    position: fixed;
    right: 0;
    z-index: -1;
    object-fit: cover;
    object-position: top right;

    @media (min-width: 768px) {
      width: 65%;
    }
  }

  h1 {
    color: white;
    letter-spacing: -4.4px;
    font-family: "HK Grotesk", sans-serif;
    span {
      font-weight: 900;
      color: $yellow;
    }
  }

  .content_container {
    position: relative;
    z-index: 1;
  }
}

@media screen and (max-width: 1024px) {
  .layout {
    .logo {
      padding: 30px 0 40px 0;
    }
    h1 {
      letter-spacing: -2.2px;
      font-size: clamp(40px, 14vw, 50px);
    }
  }
}
