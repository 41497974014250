@import "../../../styles/variables";

.videoModal {
  position:        fixed;
  z-index:         100;
  left:            0;
  top:             0;
  width:           100%;
  height:          100%;
  display:         flex;
  align-items:     center;
  justify-content: center;
  .overlay {
    background: rgba(black, 90%);
    position:   absolute;
    z-index:    1;
    left:       0;
    top:        0;
    width:      100%;
    height:     100%;
  }
  .content {
    background: rgba(black, 100%);
    position:   relative;
    z-index:    2;
    border:     10px solid $yellow;
    width:      96%;
    max-width:  900px;
    padding:    50px 80px;
    .close {
      padding:  10px;
      position: absolute;
      z-index:  2;
      right:    10px;
      top:      10px;
      cursor:   pointer;
      img {
        width: 18px;
      }
      &:hover, &:active {
        opacity: .7;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .videoModal {
    .content {
      padding:   20px 25px;
      max-width: 750px;
      border:    2px solid $yellow;
      .close {
        right: 0;
        top:   0;
        img {
          width: 10px;
        }
      }
    }
  }
}
