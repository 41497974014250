.container {
  width: fit-content;

  display: flex;
  flex-direction: column;
  gap: 15px;

  p {
    margin: 0;
  }
}

.description {
  letter-spacing: -0.04em;
}

.input_container {
  display: flex;
  flex-direction: row;
  gap: 20px;

  input {
    width: 220px;

    padding: 10px;
    background-color: white;

    color: black;
    border-radius: 0;
  }

  input::placeholder {
    color: gray;
  }

  .btn {
    min-width: 100px;
    min-height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 20px;
    font-weight: 900;

    background-color: #ffd600;
    border: none;
    color: black;
  }
}

.error {
  color: red;
  font-family: 'HK Grotesk';
  font-weight: 400;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus {
  color: black !important;
  -webkit-text-fill-color: black !important;
}