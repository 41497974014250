
.createAccountBtn {
    width: 357px;
    height: 45px !important;
    color: black !important;
    background: #FFD600 !important;
    border: none !important;
}

.signInText {
    font-size: 18px;
    font-weight: 500;
}

.signInModalContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.alreadyHaveAccountBtn {
    // text-decoration: none;
    background-color: unset;
    border: none;
}

.alreadyHaveAccountBtn:hover {
    text-decoration: none;
    cursor: pointer;
}