.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;

  padding-top: 75px;
  padding-bottom: 75px;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.left_column {
  display: none;

  @media (min-width: 768px) {
    position: sticky;
    top: 75px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0;
  }
}

.player_container {
  width: fit-content;
}

.player_container_wrapper {
  width: fit-content;
  position: relative;

  .title_counter {
    position: absolute;
    bottom: 0;
    left: 0;

    margin: 0;

    font-family: "HK Grotesk";
    font-size: 26px;
    font-weight: 900;
    line-height: 18px;
    letter-spacing: -0.045em;
    color: black;

    @media (min-width: 768px) {
      font-size: 30px;
    }
  }
}

.btnPost {
  min-height: 40px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 20px;
  font-weight: 900;

  background-color: #ffd600;
  border: none;
  color: black;
  cursor: pointer;
}

.countdown {
  width: 100%;
  max-width: 400px;

  display: flex;
  justify-content: center;

  text-align: right;

  margin: 0;
  margin-bottom: 10px;

  .yellow {
    font-size: 30px;
    font-weight: 900;
    color: black;
    margin-bottom: 2px;
    padding-right: 40px;
    display: inline-block;
    position: relative;
    z-index: 2;
    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      bottom: 14%;
      left: 0;
      width: 100%;
      height: 90%;
      background-color: #ffd600;
    }
  }
  .timer {
    display: inline-block;
  }
  .play {
    width: 68px;
    cursor: pointer;
    transition: 0.3s linear;
    margin-bottom: 20px;
    &:hover,
    &:active {
      opacity: 0.7;
    }
  }
}

.right_column {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .title_container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      width: 365px;
      font-size: 90px;
      letter-spacing: -0.05em;

      @media (min-width: 768px) {
        width: 410px;
        font-size: 100px;
      }
    }

    .artist_name {
      width: fit-content;
      align-self: flex-start;

      font-family: "Roboto", sans-serif;
      font-size: 30px;
      font-weight: 900;

      margin-left: 45px;
      padding: 6px 15px;

      background-color: black;
    }

    .sub_text {
      width: fit-content;

      font-family: "HK Grotesk";
      font-size: 45px;
      font-weight: 200;
      line-height: 32px;
      letter-spacing: -0.03em;
      color: black;

      background-color: #ffd600;

      padding: 0 8px 10px 8px;

      @media (min-width: 768px) {
        font-size: 50px;
        line-height: 35px;
      }
    }
  }

  .content_container {
    width: auto;

    border-radius: 20px;
    background: rgba(0, 0, 0, 0.6);

    padding: 30px 30px 0 40px;

    letter-spacing: -0.03em;

    .content_text {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.03em;

      span {
        font-weight: bold;
      }
    }

    .margin_0 {
      margin: 0;
    }

    .yellow_text {
      color: #ffd600;
      font-weight: 900;
    }

    .content_title {
      font-size: 30px;
      font-weight: 900;
      color: #ffd600;
    }

    @media (min-width: 768px) {
      width: 410px;
    }

    .icons_container {
      width: 100%;

      display: flex;
      flex-direction: row;
      justify-content: space-between;

      margin-bottom: 20px;

      a {
        min-width: 35px;
        margin: auto;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .citation_container {
      display: flex;
      flex-direction: column;
    }
  }

  .register_wrapper {
    display: flex;
    justify-content: center;
  }
}

.benefits_container {
  .benefit {
    display: flex;
    flex-direction: row;
    gap: 15px;

    img {
      width: 30px !important;
      height: 30px;
    }

    p {
      font-weight: 600;
      font-size: 16px;
      letter-spacing: -0.05em;

      @media (min-width: 768px) {
        font-size: 18px;
      }
    }
  }
}
